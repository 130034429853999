<template lang="pug">
FGrid.FcwImagesMosaic(
  gap="24"
  columns="6"
)
  FGridItem.FcwImagesMosaic__cardWrapper(
    v-for="(image, index) in images"
    :key="index"
    :span="gridItemPattern[index]"
    md="6"
  )
    FCard.FcwImagesMosaic__card
      FcwRouterLink(
        :location="image.link ?? ''"
        hide-arrow
      )
        FImage.FcwImagesMosaic__cardImage(
          :src="image.src"
          :alt="image.alt"
          height="100%"
          width="100%"
          fit="cover"
        )
</template>

<style lang="stylus">
.FcwImagesMosaic__card
  position relative
  overflow hidden
  height rem(348)
  width 100%

  a,
  .FLink__content
    height 100%
    width 100%

  &:hover
    .FcwImagesMosaic__cardImage
      transform scale(1.1)

  +media-down('sm')
    min-height rem(280)

.FcwImagesMosaic__cardWrapper
  width 100%
  display flex
  justify-content center
  overflow hidden
  border-radius rem(16)

.FcwImagesMosaic__cardImage
  transition transform 400ms ease

  &:hover
    transform scale(1.1)
</style>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router';
import type { FGridItemProps, FImageProps } from '@fifteen/design-system-vue';

export interface ImageWithLink extends FImageProps {
  /**
   * Link
   */
  link?: RouteLocationRaw;
}

export interface FcwImagesMosaicProps {
  /**
   * Array of images with link
   */
  images?: ImageWithLink[];
}

withDefaults(defineProps<FcwImagesMosaicProps>(), {
  images: () => [],
});

// Pattern that defines the grid row span
const gridItemPattern: FGridItemProps['span'][] = ['3', '3', '2', '4'];
</script>
