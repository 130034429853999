<template lang="pug">
.FcwHighlightGrid
  .FcwHighlightGrid__blocks
    .FcwHighlightGrid__block(
      v-for="(item, index) in items.slice(0, 4)"
      :key="index"
    )
      .FcwHighlightGrid__block__imgWrapper
        FImage(
          v-if="item.image"
          v-bind="item.image"
          loading="eager"
          corners="sharp"
          width="100%"
          height="100%"
          fit="cover"
        )
      .FcwHighlightGrid__content(:style="getBackgroundColor(index)")
        .FcwHighlightGrid__block__text
          h4.FcwHighlightGrid__block__title {{ item.title }}
          p.FcwHighlightGrid__block__description {{ item.description }}
        FcwButtonLink(
          v-if="item.buttonText"
          :location="item.buttonLink"
        ) {{ item.buttonText }}
</template>

<style lang="stylus">
.FcwHighlightGrid
  overflow hidden
  padding 0
  margin-top 0

.FcwHighlightGrid__blocks
  display flex
  width 100%

  +media-down('md')
    flex-wrap wrap

.FcwHighlightGrid__block__imgWrapper
  width 100%
  height 100%
  position absolute
  top 0
  left 0
  z-index 0

.FcwHighlightGrid__block
  min-height rem(360)
  flex-basis rem(320)
  position relative
  flex-grow 1
  text-align center

  +media-down('md')
    flex-basis 50%

    &:nth-child(3)
      // Put the 3rd item on 4th position on md screens to have a checkerboard pattern
      order 4

  +media-down('xs')
    flex-basis 100%

    &:nth-child(3)
      order unset

.FcwHighlightGrid__content
  display flex
  flex-direction column
  align-items center
  justify-content space-between
  padding rem(64)
  height 100%
  position relative
  z-index 1

  +media-down('md')
    padding rem(48)
    justify-content center

.FcwHighlightGrid__block__title
  margin-bottom rem(24)

.FcwHighlightGrid__block__description
  margin-bottom rem(40)
</style>

<script setup lang="ts">
import type { FImageProps } from '@fifteen/design-system-vue';
import type { RouteLocationRaw } from 'vue-router';

export interface HighlightGridItem {
  /**
   * Title of the grid item
   */
  title: string;
  /**
   * Description of the grid item
   */
  description: string;
  /**
   * Text of the CTA button
   */
  buttonText?: string;
  /**
   * Link of the CTA button
   */
  buttonLink?: RouteLocationRaw;
  /**
   * Image of the grid item
   */
  image?: Pick<FImageProps, 'src' | 'alt'>;
}

export interface FcwHighlightGridProps {
  /**
   * Array of grid item, in the limit of 4.
   */
  items: HighlightGridItem[];
}

defineProps<FcwHighlightGridProps>();

/**
 * Returns the grid item background color based on its index
 * @param index - Index of the grid item
 */
function getBackgroundColor(index: number): { backgroundColor: string } {
  const color = getCssColor(
    index % 2 === 0 ? 'primary--light-1' : 'primary--light-2',
    true
  );

  return {
    backgroundColor: `rgba(var(${color}--rgb), 0.8)`,
  };
}
</script>
